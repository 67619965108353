import axios from "axios";
import authHeader from "./auth-header";
import BaseService from "./base.service";

class ProductDocService extends BaseService {
  constructor(url) {
    super(url);
  }

  updateProductDoc(id, obj) {
    return axios.put(this.url + `/${id}`, obj, {
      headers: authHeader(this.token),
    });
  }
}

export default new ProductDocService("product-docs");
