<template>
  <div class="pa-md-10 pa-lg-10 ">
    <div class="text-center">
      <h2 v-if="crud_state == '3'">Add Product Document</h2>
      <h2 v-else-if="crud_state == '2'">Edit Product Document</h2>
    </div>
    <div class="space-30"></div>
    <v-card v-if="loading">
      <v-skeleton-loader
        v-bind="$attrs"
        type="table-heading, list-item-two-line, image"
      ></v-skeleton-loader>
    </v-card>
    <v-form v-else v-model="formValid" ref="formValid">
      <v-card class="pa-10 ">
        <v-row>
          <v-col cols="12">
            <p>Document Title</p>
            <v-text-field
              label="Enter Document Title"
              outlined
              v-model="item.name"
              dense
              rows="2"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>Product</p>
            <v-select
              :items="productItems"
              label="Enter Product"
              v-model="item.product_id"
              item-text="name"
              item-value="id"
              outlined
              clearable
              dense
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <p>Rank</p>
            <v-text-field
              label="Enter Rank"
              outlined
              v-model="item.rank"
              dense
              rows="2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="3" md="3" offset="2">
            <p>Status</p>
            <v-switch v-model="item.status"></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p>Upload Product Document</p>
            <v-file-input
              label="Upload Product Document"
              :rules="uploadRule"
              outlined
              dense
              accept=".pdf"
              v-model="item.file"
            ></v-file-input>
          </v-col>
        </v-row>

        <div>
          <v-row class="justify-end">
            <v-btn color="success" @click="validate">
              <v-icon small left>mdi-check</v-icon>
              Save
            </v-btn>
            <router-link class="no-link" to="/master-data/product-docs">
              <v-btn text>
                Cancel
              </v-btn>
            </router-link>
          </v-row>
        </div>
      </v-card>
    </v-form>
    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>
<script>
import Snackbar from "@/components/common/Snackbar";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import ProductDocService from "@/services/product-doc.service.js";
import { bus } from "@/main.js";

export default {
  name: "ProductDocAdd",

  props: {
    id: String,
  },

  components: {
    Snackbar,
  },

  data() {
    return {
      item: {},
      snackbar: {},
      formValid: false,
      loading: false,
      productItems: [],
      uploadRule: [(v) => !!this.id || !!v || `Product Document is Required`],
    };
  },

  computed: {
    crud_state() {
      return this.$route.query.crud_state;
    },
  },

  async created() {
    this.getProductsList();
    if (this.id) {
      this.item = await this.get_item(this.id);
    } else {
      this.item = {};
    }
  },

  methods: {
    validate() {
      this.$refs.formValid.validate();
      if (this.formValid) {
        this.saveProductDocument();
      }
    },
    async getProductsList() {
      let type_id = 1;
      let productService = new BaseService(`lookups/type/${type_id}`);
      let res = await productService.get_all();
      if (res) {
        this.productItems = res.data.results;
      }
    },
    async get_item(id) {
      if (!id) {
        return null;
      }
      this.loading = true;
      let service = new BaseService("product-docs");
      let res = await service.get_one(id);
      this.loading = false;
      return res.data.data;
    },
    async saveProductDocument() {
      this.loading = true;
      let service = new BaseService("product-docs");
      let res = null;

      let formData = new FormData();
      this.item.file && formData.append(`file`, this.item.file);
      if (this.item.product_id)
        formData.append(`product_id`, this.item.product_id);
      formData.append(`name`, this.item.name);
      this.item.rank && formData.append(`rank`, this.item.rank);

      try {
        if (this.crud_state == crud_state.NEW) {
          res = await service.create(formData);
        } else {
          res = await ProductDocService.updateProductDoc(this.id, formData);
        }
        this.loading = false;

        if (res.status == 401) {
          this.handleLogout();
        } else if (res.status == 200 || res.status == 201) {
          this.$store.dispatch("common/setFlashMsg", "Saved successfully");
          bus.$emit("edit_save", {
            msg: "Saved successfully",
            data: res.data.results,
          });

          this.$router.push(`/master-data/product-docs`);
        } else {
          this.$store.dispatch(
            "common/setFlashMsg",
            "An error occurred, could not save"
          );
        }
      } catch (e) {
        this.loading = false;
        this.$store.dispatch(
          "common/setFlashMsg",
          "An error occurred, could not save"
        );
      }
    },
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
  },
};
</script>
